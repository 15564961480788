 const base_url = "https://cactus-dev.nebultech.com/api/dashboard"
export const upload_path = "https://cactus-dev.nebultech.com/public/uploads/"
export const hostname = "https://cactusfrance.com/"

// const base_url = "http://localhost:3112/api/dashboard"
// export const upload_path = "http://localhost:3112/public/uploads/"

export const pingUrl = base_url + "/admin/ping";

export const login_url = base_url + "/admin/login";
export const admins_url = base_url + "/admin/getAdminsList";
export const profile_url = base_url + "/admin/getAdminProfile";
export const add_admin_url = base_url + "/admin/addAdmin";
export const getAdminUrl = base_url + "/admin/getAdminById";
export const editAdminUrl = base_url + "/admin/updateAdmin";
export const getModuleUrl = base_url + "/module/getSingModule";
export const getModulesUrl = base_url + "/module/getModules";
export const editModuleUrl = base_url + "/module/updateModule";

export const getLessonsUrl = base_url + "/lesson/fetchLessonsList";
export const getLessonUrl = base_url + "/lesson/fetchSingleLesson";
export const updateLessonUrl = base_url + "/lesson/updateLesson";
export const addLessonUrl = base_url + "/lesson/createLesson";
export const getThemesListUrl = base_url + "/theme/getThemesList";
export const getaddExercicetUrl = base_url + "/exercice/createExercice";
export const addMultipleExerciceQuestiontUrl = base_url + "/exercice/addMultipleExerciceQuestion";
export const addExerciceQuestionsWithPropositionsUrl = base_url + "/exercice/addExerciceQuestionsWithPropositions";
export const getExerciceUrl = base_url + "/exercice/getExercice";
export const updateSingleExerciceUrl = base_url + "/exercice/updateExercice";
export const updateMultipleExerciceQuestionUrl = base_url + "/exercice/updateMultipleExerciceQuestion";
export const deleteExerciceUrl = base_url + "/exercice/deleteExercice";

// Cactus pro routes
export const getBannerInfoUrl = base_url + "/clientPro/getBannerInfo";
export const getClientListUrl = base_url + "/clientPro/getClientProList";
export const createClientUrl = base_url + "/clientPro/addClientPro";
export const updateClientUrl = base_url + "/clientPro/updateClientPro";
export const getSingleClientUrl = base_url + "/clientPro/getSingleClientPro";

// Activation codes routes
export const getCodesListUrl = base_url + "/activationCode/getAllActivationCodes"
export const createCodesUrl = base_url + "/activationCode/generateActivationCodes"
export const getAllClientNamesUrl = base_url + "/clientPro/getAllClientNames"

export const getItemsUrl = base_url + "/avatar/getItemsList";
export const createItemUrl = base_url + "/avatar/createItem";
export const updateItemUrl = base_url + "/avatar/updateItem";
export const getItemCategoryUrl = base_url + "/avatar/getItemCategoryByname";
export const getSingleItemUrl = base_url + "/avatar/getItemById";
export const deleteItemUrl = base_url + "/avatar/deleteItem";
export const updateItemStatsUrl = base_url + "/avatar/updateMultipleItemStat";

export const uploadImagesUrl = base_url + "/upload/uploadImages";
export const renameFolderUrl = base_url + "/upload/renameFolder";
export const deleteFileUrl = base_url + "/upload/deleteFile";

export const getAppPramsUrl = base_url + "/appParams/getAppPrams";
export const updateAppParamsUrl = base_url + "/appParams/updateAppParams";

export const getPacksUrl = base_url + "/pack/getAllPacks";
export const updatePackUrl = base_url + "/pack/updatePack";
export const updatePackStatusUrl = base_url + "/pack/updatePackStatus";

export const addPackUrl = base_url + "/pack/createPack";
export const getSinglePackUrl = base_url + "/pack/getPackById";
export const deletePackUrl = base_url + "/pack/deletePack";

export const getPromoCodesUrl = base_url + "/promoCode/getAllPromoCodes";
export const addPromoCodeUrl = base_url + "/promoCode/createPromoCode";
export const deletePromoCodeUrl = base_url + "/promoCode/deletePromoCode";

export const getDefisUrl = base_url + "/defis/getDefisQuestionsList";
export const updateDefiUrl = base_url + "/defis/updateDefiQuestion";
export const getSingleDefiUrl = base_url + "/defis/getSingleDefisQuestion";
export const deleteDefiUrl = base_url + "/defis/deleteDefis";
export const createDefisUrl = base_url + "/defis/createDefis";

export const uploadNotifcationImagesUrl = base_url + "/upload/uploadNotifcationImages";
export const sendNotifUrl = base_url + "/notification/sendNotificationUsers";

export const getUsersUrl = base_url + "/user/getUsersList";
export const getSingleUserUrl = base_url + "/user/getSingleUser";
export const getUserProgressionsUrl = base_url + "/user/getUserProgressions";
export const getUserTransactionsUrl = base_url + "/user/getUserTransactions";
export const getAvatarStatsUrl = base_url + "/user/getAvatarStats";
export const updateUserUrl = base_url + "/user/updateUser";

export const getUserDeviceOSStatsUrl = base_url + "/analytics/getUserDeviceOSStats";
export const getUserSignupStatsUrl = base_url + "/analytics/getUserSignupStats";
export const moduleAchivemntPerenctageUrl = base_url + "/analytics/moduleAchivemntPerenctage";
export const progressionUsersModuleByLeconUrl = base_url + "/analytics/progressionUsersModuleByLecon";
export const getUserDefisStatsUrl = base_url + "/analytics/getUserDefisStats";
export const getOnboardingStatsUrl = base_url + "/analytics/getOnboardingStats";

export const getTransactionsStatsUrl = base_url + "/finance/getTransactionsStats";
export const getRevenueChartUrl = base_url + "/finance/getRevenueChart";
export const getTransactionsReportUrl = base_url + "/finance/getTransactionsReport";

export const getContractsUrl = base_url + "/contract/getContracts";
export const createContractUrl = base_url + "/contract/createContract";
export const checkDuplicatedUrl = base_url + "/contract/checkDuplicated";

export const getSingleContractUrl = base_url + "/contract/getContractById";

export const updateContractUrl = base_url + "/contract/updateContract";

export const updateIndividualContractUrl = base_url + "/contract/updateClientProContract";
export const addClientToContractUrl = base_url + "/contract/addClientToContract";
export const suspendContractUrl = base_url + "/contract/suspendContract";
export const suspendSingleClientFromAContractUrl = base_url + "/contract/suspendSingleClientFromAContract";
