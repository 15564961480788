import { Input, Col, Row, Button, Form, Checkbox, message } from "antd";
import { useEffect, useState } from "react";
import { ArrowLeftOutlined } from '@ant-design/icons';
import { ComponentStates } from '../insertCode';

export default function SignIn({ setComponentState }) {
    const [form] = Form.useForm();
    // TODO: Switch to prod
    const signInUrl = "https://cactus-dev.nebultech.com/api/app/authentication/signIn";
    const [token, setToken] = useState(null);

    const maxWidthStyle = { width: "100%" };

    const [messageApi, contextHolder] = message.useMessage();
    const [isLoading, setIsLoading] = useState(false);

    const [signInSuccess, setSignInSuccess] = useState(false);
    const [signInError, setSignInError] = useState(false);


    useEffect(() => {
        if (token && signInSuccess) {
            setIsLoading(false);
            setComponentState(ComponentStates.ActivationScreen);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

    useEffect(() => {
        if (!signInError) {
            return;
        }
        messageApi.error(signInError);
        setSignInError(false);
        setIsLoading(false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signInError])

    const onPressSignin = () => {
        let email = form.getFieldValue("email");
        let password = form.getFieldValue("password");
        let checkbox = form.getFieldValue("check");
        if (!email || !password) {
            return;
        }
        if (!checkbox) {
            checkbox = false;
        }
        let body = {
            email,
            password,
            rememberMe: checkbox
        }
        setIsLoading(true);
        fetch(signInUrl, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "device": "Web"
            },
            body: JSON.stringify(body)
        }).then(async (response) => {
            if (response.status !== 200) {
                setSignInError((await response.json()).message);
                return;
            }
            let data = await response.json();
            setToken(data.token);
            localStorage.setItem("insertUserToken", data.token);

            setSignInSuccess(true);
        }).catch((response) => {
            setSignInError("Veuillez vérifier votre connexion.");
        })
    }
    return (
        <Col className="signinContent">
            {contextHolder}
            <Row style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
            }}>
                <Button onClick={() => { setComponentState(ComponentStates.ChoiceScreen) }} style={{ margin: 0, border: "none" }} icon={<ArrowLeftOutlined />} />
            </Row>
            <Row style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "5vh",
                marginTop: "2vh"
            }}>
                <h1 style={{ color: "black", lineHeight: "normal", margin: 0, fontSize: "32px" }}>Bonjour 👋</h1>
            </Row>
            <div style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "center",
            }}>
                <Form
                    layout="vertical"
                    form={form}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        width: "70%",
                        justifyContent: "center",
                    }}
                >
                    <Form.Item name="email" label="E-mail" style={{ padding: "4px", width: "100%" }} >
                        <Input style={maxWidthStyle} rootClassName="inputField" bordered={false} placeholder="johndoe@gmail.com"></Input>
                    </Form.Item>
                    <Form.Item name="password" label="Mot de passe" style={{ marginTop: "5vh", width:"100%", padding: "4px" }}>
                        <Input.Password
                            style={maxWidthStyle}
                            rootClassName="inputField"
                            bordered={false}
                            rules={[{
                                required: true,
                                message: 'Veuillez insérer votre mot de passe!'
                            }]}
                        ></Input.Password>
                    </Form.Item>
                    <Form.Item name="check" valuePropName="checked" id="rememberMe" style={{ width: "100%" }}>
                        <div style={{width:"fit-content"}}>
                            <Checkbox style={{ justifySelf: "flex-start", width: "fit-content" }}>Se souvenir de moi</Checkbox>

                        </div>
                    </Form.Item>
                    <Form.Item style={maxWidthStyle}>
                        <Button
                            className="customButton"
                            loading={isLoading}
                            style={{
                                color: "#fff",
                                backgroundColor: "#39B13D",
                                borderRadius: "100px",
                                width: "100%",
                                marginLeft: 0,
                                marginRight: 0,
                                marginTop: "10vh"
                            }}
                            htmlType="submit"
                            onClick={onPressSignin}

                        >S'IDENTIFIER</Button>
                    </Form.Item>
                </Form>
            </div>
        </Col>
    )
}